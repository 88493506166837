window.addEventListener('load', () => {
    burgerAction();
    scrollToTop();
    popup();
});

window.addEventListener('resize', () => {
});
  
const body = document.querySelector('body');
const blankJS = body.querySelector('.js-header-blank');

// Open burger
function burgerAction() {
    const burger = body.querySelector('.header .js-burger');
    const menuMain = body.querySelector('.header .header-nav-wrapper');

    burger.addEventListener('click', ()=>{
        if ( burger.classList.contains('active') ) {
            burger.classList.remove('active');
            menuMain.classList.remove('active');
            blankJS.classList.remove('show');
        } else {
            burger.classList.add('active');            
            menuMain.classList.add('active');
            blankJS.classList.add('show');
        }
    });

    const subMenus = menuMain.querySelectorAll('.menu-item-has-children');

    subMenus.forEach(element => {
        const link = element.querySelector('a');
        const menu = element.querySelector('ul');

        window.addEventListener('click', (e)=>{
            if ( !e.target.classList.contains('menu-ul') ) {
                menuMain.classList.remove('grey');
                blank(menu, 'remove');
                menu.classList.remove('active');
                blankJS.classList.remove('active');
            }
        });

        link.addEventListener('click', (e)=> {
            e.preventDefault();
            setTimeout(() => {
                if (e.pointerType != 'touch') {
                    menuMain.classList.add('grey');
                    blank(menu);
                    menu.classList.add('active');
                    blankJS.classList.add('active');           
                } else {
                    if (!menu.classList.contains('active')) {
                        menuMain.classList.add('grey');
                        blank(menu);
                        menu.classList.add('active');
                        blankJS.classList.add('active');
                    } else {
                        menuMain.classList.remove('grey');
                        blank(menu, 'remove');
                        menu.classList.remove('active');
                        blankJS.classList.remove('active');
                    }
                }
            }, 100);
        });   
    });
}

// It's sub menu background zise
function blank(element, action = 'add') {
    const rect = element.getBoundingClientRect();

    if (action == 'add') {
        blankJS.style.width = rect.right + 'px';
        blankJS.style.height = rect.bottom + 'px';
    } else {
        blankJS.style.width = '0px';
        blankJS.style.height = '0px';    
    }
}

// Scroll to top
function scrollToTop() {
    const button = body.querySelector('.scroll-to-top');

    button.addEventListener('click', ()=> {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Optional: Enables smooth scrolling animation
        });
    });
}

// Popup 
function popup() {
    const buttons = body.querySelectorAll('.js-open-popup');

    buttons.forEach(button => {
        button.addEventListener('click', (e)=> {
            e.preventDefault();
            const id = button.dataset.id;
            const popup = body.querySelector('.js-popup'+id);
            const close = popup.querySelector('.close-popup');
            popup.classList.add('show');
            body.classList.add('no-scroll');
            const form = popup.querySelector('form');
            if (form) {
                formActions(popup);
            }

            // close on close button
            close.addEventListener('click', ()=>{
                popup.classList.remove('show');
                body.classList.remove('no-scroll');
                button.focus();
            })

            // Close on click by background and go back to button
            popup.addEventListener('click', (e)=> {
                const target = e.target;

                if (target === popup) {
                    popup.classList.remove('show');
                    body.classList.remove('no-scroll');                        
                    button.focus();
                }
            })
        })
    });
}

function formActions(popup) {
    const form = popup.querySelector('form input');
    const formButton = popup.querySelector('.gform_button');
    const proposal = popup.querySelector('.proposal-button');
    form.focus();

    formButton.addEventListener('mouseover', ()=>{
        proposal.classList.add("hover");
    })

    formButton.addEventListener('mouseout', ()=>{
        proposal.classList.remove("hover");
    })

}

/*
function openDesktopSubMenu() {
    const menu = body.querySelector('.nav-main-desktop');
    const allLinks = menu.querySelectorAll('.main-link');
    const links = menu.querySelectorAll('.main-link.has-child');
    const sub = menu.querySelectorAll('.menu-sub-wrapper');

    sub.forEach((element) => {
        element.addEventListener('mouseleave', ()=> {
            element.classList.remove('active');
        });
    });

    links.forEach((link, id) => {
        link.addEventListener('click', (e)=> {
            e.preventDefault();
        });
        link.addEventListener('mouseenter', (e)=> {
            e.preventDefault();

            sub.forEach(element => {
                element.classList.remove('active');
            });
            links.forEach(element => {
                element.classList.remove('active');
            });
            sub[id].classList.add('active');
            link.classList.add('active');
        });
    });

    allLinks.forEach(link => {
        link.addEventListener('mouseenter', ()=> {
            if (!link.classList.contains('has-child')) {
                sub.forEach(element => {
                    element.classList.remove('active');
                });
                links.forEach(element => {
                    element.classList.remove('active');
                });
            }
        });
    });
}

function hiddenDescktopMenuOnScroll() {
    const menu = body.querySelector('.nav-main-desktop');
    const header = body.querySelector('.header .header-wrapper');

    if (window.scrollY > 300) {
        menu.classList.remove('show');
        header.classList.add('min');
      } else {
        menu.classList.add('show');
        header.classList.remove('min');
    }
}

function openMobileSubMenu() {
    const menus = body.querySelectorAll('.nav-main-mobile .menu-item-has-children');

    menus.forEach(menu => {
        menu.addEventListener('click', (e)=>{
            e.preventDefault();

            menus.forEach(element => {
                element.classList.remove('open');
            });
            menu.classList.add('open');
        });
    });
}
function video() {
    const videos = body.querySelectorAll('.video.file');

    videos.forEach(element => {
        if (element) {
            const video = element.querySelector('video');
            const button = element.querySelector('.button-play');
            video.currentTime = 1;

            element.addEventListener('click', (e)=> {
                const target = e.target;
                
                if (target != button) {
                    element.classList.remove('play');
                    video.pause();
                }
            });

            button.addEventListener('click', ()=> {
                element.classList.add('play');
                video.play();
            });
        }
    });
}
*/